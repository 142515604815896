<app-router-loading-indicator></app-router-loading-indicator>

<app-notification></app-notification>

<main
	[@routerTransition]="outlet.isActivated ? outlet.activatedRoute : ''"
	(@routerTransition.start)="pageAnimationStarted()"
	(@routerTransition.done)="pageTransitioned()">
	<router-outlet (activate)="onActivate($event) " #outlet="outlet"></router-outlet>
</main>
