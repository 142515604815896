import {Injectable} from '@angular/core';
import {NotificationMessage} from '../notification-message';
import {BehaviorSubject, Observable} from 'rxjs';
import {TickUtils} from '../../../utils/tick-utils';


@Injectable()
export class NotificationService {

	private id: number                                             = 0;
	private messagesList: NotificationMessage[]                    = [];
	private observableList: BehaviorSubject<NotificationMessage[]> = new BehaviorSubject<NotificationMessage[]>( [] );



	constructor () { }



	private showMessage ( content: string, style: string, dismissDelay: number ) {
		this.id++;
		this.messagesList.unshift( new NotificationMessage( this.id, content, style ) );
		this.observableList.next( this.messagesList );

		TickUtils.runInNext( function ( messageId: number ) {
			this.dismissMessage( messageId );
		}.bind( this, this.id ), dismissDelay );
	}



	getMessages (): Observable<NotificationMessage[]> {
		return this.observableList.asObservable();
	}



	showInfo ( content: string, dismissDelay: number = 2000 ) {
		this.showMessage( content, 'is-info', dismissDelay );
	}



	showDanger ( content: string, dismissDelay: number = 2000 ) {
		this.showMessage( content, 'is-danger', dismissDelay );
	}



	showSuccess ( content: string, dismissDelay: number = 2000 ) {
		this.showMessage( content, 'is-success', dismissDelay );
	}



	dismissMessage ( messageId ) {
		document.getElementById( 'notification-' + messageId ).classList.add( 'notification-fade-out' );
		TickUtils.runInNext( () => {
			this.messagesList.splice( this.messagesList.findIndex( msg => msg.id === messageId ), 1 );
			this.observableList.next( this.messagesList );
		}, 500 );
	}

}
