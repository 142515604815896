import {Injectable} from '@angular/core';
import * as QRCode from 'easyqrcodejs/src/easy.qrcode.js';
import {IQRCodeOptions} from './data.service';


@Injectable( {
	providedIn: 'root'
} )
export class QrCodeService {

	constructor () { }



//subTitleTop: qrCodeOptions.height ? qrCodeOptions.height + 4 : null,
	public generate ( element: HTMLElement, qrCodeOptions: IQRCodeOptions ): QRCode {
		// console.log( 'el', element );
		// console.log( 'qr', qrCodeOptions );
		// console.log( '----' );

		const titleFontSize = qrCodeOptions.value.length >= 20 ? 10 : 14;

		return new QRCode( element, {
			text: qrCodeOptions.value,

			title     : qrCodeOptions.header && qrCodeOptions.header.title ? qrCodeOptions.header.title : null,
			titleFont : `bold ${titleFontSize}px Arial`,
			titleColor: '#000000',

			subTitle     : qrCodeOptions.header && qrCodeOptions.header.subtitle ? qrCodeOptions.header.subtitle : null,
			subTitleFont : '10px Arial',
			subTitleColor: '#505050',

			titleTop   : -6,
			subTitleTop: qrCodeOptions.height ? qrCodeOptions.height + 22 : null,

			quietZone: 34,

			width : qrCodeOptions.width ? qrCodeOptions.width : null,
			height: qrCodeOptions.height ? qrCodeOptions.height : null,

			logo: qrCodeOptions.logo ? qrCodeOptions.logo.image : null,
			// logoBackgroundColor: qrCodeOptions.logo && qrCodeOptions.logo.backgroundColor ? qrCodeOptions.logo.backgroundColor : null,
			logoWidth : qrCodeOptions.logo && qrCodeOptions.logo.width ? qrCodeOptions.logo.width : null,
			logoHeight: qrCodeOptions.logo && qrCodeOptions.logo.height ? qrCodeOptions.logo.height : null,

			correctLevel: QRCode.CorrectLevel.H,
			version     : 2
		} );
	}
}
