<div class="is-notification-wrapper">
	<aside *ngFor="let message of messages$ | async">
		<div class="notification"
			 [attr.id]="'notification-'+message.id"
			 [ngClass]="message.style">

			<button class="delete" (click)="dismiss(message.id)"></button>
			{{message.content}}
		</div>

	</aside>
</div>
