
<section class="section">
	<div class="container">
		<div class="columns">

			<div class="column">
				<h1 class="title has-text-dark">
					<fa-icon [icon]="'qrcode'" size="xs"></fa-icon>
					{{ 'QR Code Printer' | translate }}
				</h1>
				<p class="subtitle is-small has-text-dark">
					Below you will find your KeepGood QR Codes.
				</p>
			</div>

			<div class="column has-text-right">
				<h1 class="title">&nbsp;</h1>
				<p class="subtitle is-small help">
					Press <code>CTRL + P</code> for print.
				</p>
			</div>

		</div>

	</div>
</section>

<section>
	<app-qr-matrix [qrCodes]="(codes$ | async)"></app-qr-matrix>
</section>
