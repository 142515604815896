<section class="hero is-danger is-bold is-large">
	<div class="hero-body">
		<div class="container">
			<h1 class="title is-1">
				{{'Error' | translate}}
			</h1>
			<h2 class="subtitle" *ngIf="error">
				Error: {{error.name}} ({{error.status}})
				<span *ngIf="error.message">
					/ {{error.message}}
				</span>
			</h2>
		</div>
	</div>
</section>
