import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TestRestService} from './rest/test-rest.service';
import {ResourceModule} from '@ngx-resource/handler-ngx-http';
import {GlobalErrorHandler} from './middleware/global-error-handler';
import {ResponseInterceptor} from './middleware/response-interceptor';
import {LoggerModule} from './modules/logger/logger.module';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PrintPageComponent} from './pages/print-page/print-page.component';
import {AppLoadingIndicatorService} from './services/app-loading-indicator.service';
import {AppLoadingDataService} from './services/app-loading-data.service';
import {RouterLoadingIndicatorComponent} from './components/router-loading-indicator/router-loading-indicator.component';
import {NotificationModule} from './components/notification/notification.module';
import { QrMatrixComponent } from './components/qr-matrix/qr-matrix.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';



export function HttpLoaderFactory ( http: HttpClient ) {
	return new TranslateHttpLoader( http, './assets/localization/', '-lang.json' );
}



export function waitForAnimationServiceFactory ( appLoadingIndicatorService: AppLoadingIndicatorService ) {
	return () => appLoadingIndicatorService.wait();
}



@NgModule( {
	declarations: [
		AppComponent,
		ErrorPageComponent,
		PrintPageComponent,
		RouterLoadingIndicatorComponent,
		QrMatrixComponent,
		QrCodeComponent
	],
	imports     : [
		FormsModule,
		BrowserModule,
		CommonModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		FontAwesomeModule,
		LoggerModule.forRoot( {url: null} ),
		ResourceModule.forRoot(),
		TranslateModule.forRoot( {
			loader: {
				provide   : TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps      : [ HttpClient ]
			}
		} ),
		NotificationModule
	],
	providers   : [
		AppLoadingIndicatorService,
		AppLoadingDataService,
		{
			provide   : APP_INITIALIZER,
			useFactory: waitForAnimationServiceFactory,
			deps      : [ AppLoadingIndicatorService, AppLoadingDataService ],
			multi     : true
		},
		{
			provide : ErrorHandler,
			useClass: GlobalErrorHandler
		},
		{
			provide : HTTP_INTERCEPTORS,
			useClass: ResponseInterceptor,
			multi   : true
		},


		TestRestService,
	],
	bootstrap   : [ AppComponent ]
} )
export class AppModule {

	constructor ( library: FaIconLibrary ) {
		/**
		 * Load full free icons library. Thanks that there is no need to load every icon independent like that:
		 *** import { faCoffee } from '@fortawesome/free-solid-svg-icons';
		 */
		library.addIconPacks( fas );
	}
}
