import {Injectable} from '@angular/core';
import {
	IResourceMethodPromise,
	Resource,
	ResourceAction,
	ResourceActionReturnType,
	ResourceHandler,
	ResourceParams,
	ResourceRequestMethod
} from '@ngx-resource/core';


@Injectable()
@ResourceParams(
	{
		pathPrefix: 'REST/',
		returnAs: ResourceActionReturnType.Promise
	}
)
export class TestRestService extends Resource {

	constructor ( handler: ResourceHandler ) {
		super( handler );
	}



	@ResourceAction( {
		method : ResourceRequestMethod.Get,
		path   : '/get'
	} )
	get: IResourceMethodPromise<any, any>;

	@ResourceAction( {
		method : ResourceRequestMethod.Post,
		path   : '/post'
	} )
	post: IResourceMethodPromise<any, any>;

	@ResourceAction( {
		method : ResourceRequestMethod.Get,
		path   : '/gett'
	} )
	get404: IResourceMethodPromise<any, any>;
}
