export class NotificationMessage {
	id: number;
	content: string;
	style: string;

	constructor ( id: number, content: string, style? ) {
		this.id      = id;
		this.content = content;
		this.style   = style || 'is-info';
	}

}
