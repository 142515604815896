import {Component, HostBinding} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {routerTransition} from './router-transition';
import {LoggerService} from './modules/logger/logger.service';


@Component( {
	selector   : 'app-root',
	templateUrl: './app.component.html',
	styleUrls  : [ './app.component.scss' ],
	animations : [ routerTransition ],
} )
export class AppComponent {

	@HostBinding( 'class.page-transitioned' ) pageAnimationFinished: boolean = false;

	constructor ( translate: TranslateService, public logger: LoggerService ) {
		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang( 'en' );

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use( 'en' );
	}



	pageAnimationStarted () {
		this.pageAnimationFinished = false;
	}



	pageTransitioned () {
		this.pageAnimationFinished = true;
	}



	onActivate ( e ) {
		const componentName = e.constructor.name;
		this.logger.info( 'Activate ' + componentName );
	}


}
