export class TickUtils {

	public static runInNext ( func: Function, delay: number = 0 ) {
		return setTimeout( func, delay );
	}



	public static clear ( timeoutId: number ) {
		clearTimeout( timeoutId );
	}



	public static waitForNext ( delay: number = 0 ): Promise<any> {
		return new Promise( resolve =>
			setTimeout( resolve, delay )
		);
	}
}
