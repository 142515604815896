import {Component, OnInit} from '@angular/core';
import {NotificationService} from './services/notification.service';
import {Observable} from 'rxjs';
import {NotificationMessage} from './notification-message';


@Component( {
	selector   : 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls  : [ './notification.component.scss' ]
} )
export class NotificationComponent implements OnInit {


	messages$: Observable<NotificationMessage[]>;



	constructor ( private notificationService: NotificationService ) { }



	ngOnInit () {
		this.messages$ = this.notificationService.getMessages();
	}



	dismiss ( itemKey ) {
		this.notificationService.dismissMessage( itemKey );
	}



	trackById ( index: number, message: NotificationMessage ): number {
		return message.id;
	}
}
