import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';


@Component( {
	selector   : 'error-page',
	templateUrl: './error-page.component.html'
} )
export class ErrorPageComponent implements OnInit {

	error: HttpErrorResponse | Error;



	constructor ( router: Router ) {
		this.error = router.getCurrentNavigation().extras.state as HttpErrorResponse | Error;
	}



	ngOnInit () {}

}
