import {ErrorHandler, Injectable} from '@angular/core';
import {LoggerService} from '../modules/logger/logger.service';
import {RequestActionError} from './response-interceptor';


/**
 * Global Error Handler will caught all errors manage them and in base of them send logs to our error server.
 */

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor ( private logger: LoggerService ) { }



	handleError ( error: Error | typeof RequestActionError ) {
		if (error.constructor === RequestActionError) {
			this.logger.info( 'Caught http response error. It should be served by ResponseInterceptor. No action needed.' );
		} else {
			this.logger.error( 'Uncaught error!', error);

			// IMPORTANT: Rethrow the error otherwise it gets swallowed
			throw error;
		}
	}

}
