import {Injectable} from '@angular/core';
import {TickUtils} from '../utils/tick-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';


export interface IQRLogo {
	image: string;
	backgroundColor?: string;
	backgroundTransparent?: boolean;
	width?: number;
	height?: number;
}

export interface IQRHeader {
	title: string;
	subtitle: string;
}

export interface IQRCodeOptions {
	value: string;
	logo?: IQRLogo;
	header?: IQRHeader;
	width?: number;
	height?: number;
}

@Injectable( {
	providedIn: 'root'
} )
export class DataService {

	private tagIds: string[];
	private titles: string[];
	private domain: string;
	private qrCodes: BehaviorSubject<IQRCodeOptions[]> = new BehaviorSubject( null );



	constructor ( private activatedRoute: ActivatedRoute, private router: Router ) {
		this.activatedRoute.queryParams.subscribe( params => {
			this.tagIds = params.tags ? (params.tags as String).split( ',' ).map( tag => tag.trim() ) : [];
			this.titles = params.titles ? (params.titles as String).split( ',' ).map( title => title.trim() ) : [];
			this.domain = params.domain ? params.domain : '';

			this.emit();
		} );
	}



	async set ( tags: string ) {
		this.router.navigate( [], {
			relativeTo : this.activatedRoute,
			queryParams: {
				tags: tags
			}
		} );
	}



	get () {
		return this.qrCodes;
	}



	emit (): void {

		TickUtils.waitForNext( 250 ); // for loading test

		this.qrCodes.next( this.tagIds.map( ( tagId, index ) => {
			return {
				value : (this.domain ? this.domain + '/#/show/' : '') + tagId,
				header: this.getHeader( this.titles[ index ] ? this.titles[ index ] : tagId ),
				width : this.getSizeForA4(),
				height: this.getSizeForA4(),
				logo  : this.getLogoForA4()
			};
		} ) );
	}



	private getSizeForA4 (): number {
		return 136;
	}



	private getLogoForA4 (): IQRLogo {
		return {
			image          : 'assets/qr-logo.png',
			backgroundColor: 'white',
			width          : 35,
			height         : 35
		};
	}



	private getHeader ( value: string ): IQRHeader {
		return {
			title   : this.getTitle( value ),
			subtitle: this.getSubtitle()
		};
	}



	private getTitle ( value: string ): string {
		return value;
	}



	private getSubtitle (): string {
		return 'KeepGood.it';
	}
}
