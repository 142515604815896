import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse}
	from '@angular/common/http';
import {Router} from '@angular/router';
import {LoggerService} from '../modules/logger/logger.service';
import {Observable, Subscriber} from 'rxjs';
import {NotificationService} from '../components/notification/services/notification.service';

/**
 * Http Statuses needed for control request errors.
 */

export enum HttpStatus {
	FORBIDDEN             = 409,
	FORBIDDEN_403         = 403,
	ACCESS_DENIED         = 401,
	INTERNAL_SERVER_ERROR = 500,
	NOT_FOUND             = 404,
	METHOD_NOT_ACCEPTABLE = 406,
	METHOD_NOT_ALLOWED    = 405,
}

/**
 * RequestActionError used for throw Exception.
 * @param error
 * @constructor
 */
export const RequestActionError = function ( errorResponse: HttpErrorResponse ) {
	if (Error.hasOwnProperty( 'captureStackTrace' )) { // V8
		Error[ 'captureStackTrace' ]( this, this.constructor );
	} else {
		Object.defineProperty( this, 'stack', {
			enumerable: false,
			writable  : false,
			value     : (new Error( errorResponse.error )).stack
		} );
	}
	this.name  = this.constructor.name;
	this.error = errorResponse;
};

/**
 * Response Interceptor catch all request, and create new Observable used by service request.
 *
 * In most cases it should control errors, but thanks 'next' can also change returned value.
 *
 * If we want take control over some error, then in case we can make every action. Also we can prepare and throw our Error.
 *
 * In case where we want take control over succes returned data, then its possible for `next` method. If we want dont whant return
 * variable to service that make rest, then we can do that, but we need to be sure that `subscriber.complete()` was not innited before
 * we will prepare and push new variable.
 */

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

	constructor ( private router: Router, public notificationService: NotificationService, public loggerService: LoggerService ) {
	}



	intercept (
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {


		/* Use subscriber for control http request event */
		return Observable.create( ( subscriber: Subscriber<any> ) => {
			next.handle( req ).subscribe( next => {

				/* If subscriber need to wait for next value, then be sure that complite was not inited. */
				subscriber.next( next );

			}, ( error: HttpErrorResponse ) => {
				const notificationDelay = 5000;

				switch (error.status) {
					case HttpStatus.METHOD_NOT_ACCEPTABLE:
						this.loggerService.warn( 'Response Interceptor:', 'Method Not Acceptable' );
						this.notificationService.showDanger( this.getErrorNotification(error), notificationDelay );
						break;

					case HttpStatus.METHOD_NOT_ALLOWED:
						this.loggerService.warn( 'Response Interceptor:', 'Method Not Allowed' );
						this.notificationService.showDanger( this.getErrorNotification(error), notificationDelay );
						break;


					case HttpStatus.ACCESS_DENIED:
						this.loggerService.warn( 'ResponseInterceptor', 'Request Unauthorized (401)' );
						this.notificationService.showDanger( this.getErrorNotification(error), notificationDelay );
						break;

					case HttpStatus.INTERNAL_SERVER_ERROR:
						this.loggerService.warn( 'ResponseInterceptor', 'Request Internal Server Error (500), need to be controlled' );
						this.showErrorPage( error );
						break;

					case HttpStatus.FORBIDDEN_403:
					case HttpStatus.FORBIDDEN:
						this.loggerService.warn( 'ResponseInterceptor', 'Request Forbidden (409 | 403), server dont allow for make this action' );
						this.notificationService.showDanger( this.getErrorNotification(error), notificationDelay );
						break;

					case HttpStatus.NOT_FOUND:
						this.loggerService.warn( 'ResponseInterceptor', 'Request Url Not Found (404)' );
						this.showErrorPage( error );
						break;

					default:
						subscriber.error( error );
						throw new RequestActionError( error );
				}

				// subscriber.error( new RequestActionError(error) );
				throw new RequestActionError( error );

				/* If case supported by switch, then dont throw error.*/
				subscriber.complete();

			}, () => {

				subscriber.complete();

			} );

		} );

	}



	private getErrorNotification ( requestError: HttpErrorResponse ): string {
		return 'API Request Error - ' + requestError.statusText + ' (' + requestError.status + ')';
	}



	private showErrorPage ( error: HttpErrorResponse ) {
		this.router.navigateByUrl( '/error', {
			skipLocationChange: true,
			state             : error
		} );
	}
}

