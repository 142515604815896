import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
import {PrintPageComponent} from './pages/print-page/print-page.component';


const routes: Routes = [
	{
		path      : '',
		pathMatch : 'full',
		redirectTo: 'print',
	},
	{
		path     : 'print',
		component: PrintPageComponent
	},
	{
		path     : 'error',
		component: ErrorPageComponent
	}
];

@NgModule( {
	imports: [ RouterModule.forRoot( routes, {useHash: true} ) ],
	exports: [ RouterModule ]
} )
export class AppRoutingModule {
}
