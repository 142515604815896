import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IQRCodeOptions} from "../../services/data.service";
import * as QRCode from 'easyqrcodejs/src/easy.qrcode.js';
import {QrCodeService} from "../../services/qr-code.service";


@Component( {
	selector   : 'app-qr-code',
	templateUrl: './qr-code.component.html',
	styleUrls  : [ './qr-code.component.scss' ]
} )
export class QrCodeComponent implements OnInit {

	@ViewChild( 'qrContainer', {static: true} ) container: ElementRef;



	@Input() set options ( qrCode: IQRCodeOptions ) {
		this.qrCode = this.qrCodeService.generate( this.container.nativeElement, qrCode );
	};



	private qrCode: QRCode;



	constructor ( private qrCodeService: QrCodeService ) { }



	ngOnInit (): void {
	}

}
