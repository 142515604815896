import {Component, Input} from '@angular/core';
import {
	NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router,
	RouterEvent
} from '@angular/router';


@Component( {
	selector   : 'app-router-loading-indicator',
	templateUrl: './router-loading-indicator.component.html',
	styleUrls  : [ './router-loading-indicator.component.scss' ]
} )

/**
 * Component showing loading indicator that load first to 50% and when NavigationEnd or RouteConfigLoadEnd
 * then update progress value to 100%.
 *
 * For Animation its use interval.
 */

export class RouterLoadingIndicatorComponent {

	@Input( 'max' ) maxProgressValue: number = 100;

	loading: boolean      = true;
	progressValue: number = 0;

	progressAnimationInterval;
	frameTime     = 10; // ms
	frameIncrease = 2; // percent

	constructor ( private router: Router ) {
		router.events.subscribe( ( event: RouterEvent ) => {
			this.navigationInterceptor( event );
		} );
	}



	// Shows and hides the loading spinner during RouterEvent changes
	navigationInterceptor ( event: RouterEvent ): void {

		let waitForRouteLoadEnd = false;

		if (event instanceof RouteConfigLoadStart) {
			/* If routeConfidLoadStart then its loadChildren's, so we need to wait for it end */
			waitForRouteLoadEnd = true;
		}

		if (event instanceof NavigationStart) {
			this.updateProgress( 50 );
		}

		/* Check RouteConfigLoadEnd or NavigationEnd */
		if (waitForRouteLoadEnd && event instanceof RouteConfigLoadEnd) {
			this.updateProgress( this.maxProgressValue );
		}

		if (!waitForRouteLoadEnd && event instanceof NavigationEnd) {
			this.updateProgress( this.maxProgressValue );
		}

		// Set loading state to false in both of the below events to hide the spinner in case a request fails
		if (event instanceof NavigationCancel) {
			this.updateProgress( this.maxProgressValue );
		}

		if (event instanceof NavigationError) {
			this.updateProgress( this.maxProgressValue );
		}
	}



	updateProgress ( newProgressValue: number ) {
		this.loading = true;

		/* Remove existing interval */
		clearInterval( this.progressAnimationInterval );


		/* Create new animation interval */
		this.progressAnimationInterval = setInterval( () => {
			this.progressValue += this.frameIncrease;

			if (this.progressValue >= newProgressValue) {
				clearInterval( this.progressAnimationInterval );
			}

			if (this.progressValue >= this.maxProgressValue) {
				this.hideProgressbar();
			}
		}, this.frameTime );
	}



	hideProgressbar () {
		setTimeout( () => {
			this.loading = false;
		}, 500 );

		setTimeout( () => {
			this.progressValue = 0;
		}, 800 );
	}

}
