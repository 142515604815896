import {Component, Input, OnInit} from '@angular/core';
import {DataService, IQRCodeOptions} from '../../services/data.service';
import {Observable} from 'rxjs';


@Component( {
	selector   : 'app-qr-matrix',
	templateUrl: './qr-matrix.component.html',
	styleUrls  : [ './qr-matrix.component.scss' ]
} )
export class QrMatrixComponent implements OnInit {

	@Input() qrCodes: IQRCodeOptions[];

	itemsInPage: number = 3 * 5;
	codes: string;

	buildArray(ammount: number) {
		return Array.from(Array(ammount && ammount > 0 ? Math.ceil(ammount / this.itemsInPage) : 1).keys());
	}


	constructor ( private dataService: DataService ) { }



	ngOnInit (): void {}



	print () {
		console.log( 'Print', this.codes );
		this.dataService.set( this.codes );
	}

}
