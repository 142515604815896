import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationComponent} from './notification.component';
import {NotificationService} from './services/notification.service';



@NgModule( {
	providers   : [
		NotificationService
	],
	declarations: [
		NotificationComponent
	],
	imports     : [
		CommonModule
	],
	exports: [
		NotificationComponent
	]
} )
export class NotificationModule {
}
