import {Component, OnInit} from '@angular/core';
import {TestRestService} from '../../rest/test-rest.service';
import {NotificationService} from '../../components/notification/services/notification.service';
import {DataService, IQRCodeOptions} from '../../services/data.service';
import {Observable} from 'rxjs';


@Component( {
	selector   : 'app-print-page',
	templateUrl: './print-page.component.html',
	styleUrls  : [ './print-page.component.scss' ]
} )
export class PrintPageComponent implements OnInit {

	constructor ( public testRestService: TestRestService, private notification: NotificationService, private dataService: DataService ) {}


	codes$: Observable<IQRCodeOptions[]>;



	ngOnInit (): void {
		this.test();
	}



	async test () {

		this.codes$ = this.dataService.get().asObservable();

		// const aRequestResult = await this.testRestService.get( null, {
		// 	value1: 1,
		// 	value2: 2
		// }, null );
		// console.log( 'a', aRequestResult );
		//
		// const bRequestResult = await this.testRestService.post( {value: 'v1'}, {value1: 1} );
		// console.log( 'b', bRequestResult );
		//
		//
		// // SHow toast that user will see error in a moment.
		// this.notification.showInfo( 'In a moment app will init broken rest request which generate 404 error.' );
		// await TickUtils.waitForNext( 3000 );
		//
		//
		// const err = await this.testRestService.get404( null, {
		// 	value1: 1,
		// 	value2: 2
		// }, null );
		// console.log( 'err', err );
	}
}
