import {Injectable} from '@angular/core';
import {TickUtils} from '../utils/tick-utils';


/**
 * Service downloading all base and most important data like accesses, units, actions etc. before app start.
 */
@Injectable()
export class AppLoadingDataService {

	constructor() {}


	private async getBaseData() {
		/**
		 * There You can download all needed date before run app.
		 */
		await TickUtils.waitForNext(250);
		return true;
	}


	async waitForBaseData() {
		await this.getBaseData();
	}
}
