import {ModuleWithProviders, NgModule} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ILoggerConfig} from './interfaces/logger-config';
import {LoggerService} from './logger.service';


@NgModule( {} )
export class LoggerModule {
	static forRoot ( config: ILoggerConfig ): ModuleWithProviders<any> {

		return {
			ngModule : LoggerModule,
			providers: [ LoggerService,
			             {
				             provide : 'config',
				             useValue: config
			             },
			             DatePipe,
			]
		};
	}
}
