import {Inject, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {DatePipe} from '@angular/common';
import {ILoggerConfig} from './interfaces/logger-config';
import {LogType} from './enums/log-type.enum';



const noop = (): any => undefined;


/**
 *  Logger is service for print logs in console and send them to log server.
 *
 *  For use it, you ne LoggerService in constructor and then use it like: this.loggerService.info("Message, and attachment obj as second parameter", {a: 1}
 */
@Injectable( {
	providedIn: 'root'
} )
export class LoggerService {

	// private websocket;



	constructor ( @Inject( 'config' ) private config: ILoggerConfig, private date: DatePipe ) {

		// if (url) {
		// 	this.websocket = new WebsocketHandler( url );
		// }

	}



	private invokeConsoleMethod ( type: string, message: string, args?: any ): void {
		const logFn: Function = (console)[ type ] || console.log || noop;

		message = '[' + this.date.transform( new Date(), 'dd.MM.yyyy HH:mm:ss' ) + '] ' + type + ': ' + message;

		if (args) {
			logFn.apply( console, [ message, args ] );
		} else {
			logFn.apply( console, [ message ] );
		}
	}



	private sendLog ( type: LogType, message: string, args?: any ) {
		// if (!url)
		// 	return true;
		//
		// this.websocket.send( this.buildLog( type, message, args ) );
	}



	// private buildLog ( type: LogType, message: string, args?: any ): IWebsocketPackage {
	// 	return {
	// 		logType: type,
	// 		text   : message,
	// 		// attachment: args
	// 	};
	// }



	info ( message: string, args?: any ): void {
		this.invokeConsoleMethod( LogType.Info.toLowerCase(), message, args );
		this.sendLog( LogType.Info, message, args );
	}



	warn ( message: string, args?: any ): void {
		this.invokeConsoleMethod( LogType.Warning.toLowerCase(), message, args );
		this.sendLog( LogType.Warning, message, args );
	}



	error ( message: string, args?: any ): void {
		this.invokeConsoleMethod( LogType.Error.toLowerCase(), message, args );
		this.sendLog( LogType.Error, message, args );
	}



	debug ( message: string, args?: any ): void {
		if (!environment.production) {
			this.invokeConsoleMethod( LogType.Info.toLowerCase(), message, args );
		}
	}
}
