import {Injectable} from '@angular/core';
import {AppLoadingDataService} from './app-loading-data.service';
import {TickUtils} from "../utils/tick-utils";


@Injectable()
export class AppLoadingIndicatorService {

	constructor ( private appLoadingDataService: AppLoadingDataService ) {}



	private async hidePageIndicator () {
		const animationContainer = document.getElementById( 'animation-root' );

		if (!animationContainer) {
			return false;
		}

		animationContainer.className = animationContainer.className + ' fadeOut';

		TickUtils.runInNext( () => {
			animationContainer.remove();
		}, 500 );
	}



	async wait () {
		await this.appLoadingDataService.waitForBaseData();
		this.hidePageIndicator();
	}
}
