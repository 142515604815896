

<div class="is-page" size="A4" *ngFor="let i of buildArray(qrCodes.length)">
	<app-qr-code *ngFor="let qrCode of qrCodes | slice: i*itemsInPage : i*itemsInPage + itemsInPage" [options]="qrCode"></app-qr-code>

	<div *ngIf="qrCodes && !qrCodes.length" class="section text-center text-danger" style="text-align: center">

		<h1 class="title">
			<fa-icon [icon]="'border-none'"></fa-icon><br/>
			No codes to print.
		</h1>

		<span class="help">
			Need to set tags which should be printed.
		</span>

		<span class="help">
			You can write below your qr codes, splited by <code>comma</code>.
		</span>

		<div style="margin-top: 20px; width: 300px; margin-left: auto; margin-right: auto;" class="is-center">
			<div class="field is-horizontal has-addons">
				<div class="field-label is-normal is-small">
					<label class="label">Codes</label>
				</div>
				<div class="control">
					<div class="field">
						<p class="control is-small">
							<input id="codes" class="input is-small" type="text" placeholder="eg. A123,B456,C7890" [(ngModel)]="codes">
						</p>
					</div>
				</div>
				<div class="control">
					<a class="button is-info is-small" (click)="print()">
						Print
					</a>
				</div>
			</div>
		</div>

		<span class="help has-text-grey" style="margin-top: 20px;">
			Calibrated for NET Labels A4 68x63.
		</span>
	</div>
</div>
